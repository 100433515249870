(function () {
    'use strict';

    function main() {
        var page = wo$('#index');
        if (!page.length) return;
        var controller = {
            init: function () {

                page.find('[data-sticky_column]').stick_in_parent({
                    inner_scrolling: false,
                    parent: page.find('[data-sticky_parent]'),
                    offset_top: 70,

                    // HACK: Refresh explicitly every several ticks to solve problem with incorrect height calculation for the visible parent
                    // Smaller value might introduce unnecessary performance overhead as it will call the recalculation more frequently
                    recalc_every: 50
                });

                var productArea = page.data('product-area');
                WhistleOut.getAds(productArea);
                var firstArticle = page.find('div[data-articles] div:first-child');
                if (firstArticle.data('article') !== undefined) {
                    firstArticle.removeClass('bor-t-1');
                }

                page.find('#load-more a').off('click').click(function (event) {
                    event.preventDefault();
                    page.find('#load-more a .fa-spinner').show();
                    wo$('[data-enable-when-ready]').attr('disabled', 'disabled');

                    var element = page.find('[data-articles]');
                    var url = element.data('pagingurl');
                    var currentPage = element.data('page');
                    var pageSize = element.data('pagesize');

                    var firstPage = 1;
                    var skip = (currentPage - firstPage + 1) * pageSize;

                    wo$.ajax({
                        // TODO: Rename 'current' to 'skip' to avoid confusion
                        url: WhistleOut.updateQueryStringParameter(url, 'current', skip),
                        type: 'GET',
                        success: function (result) {
                            var $result = wo$(result);
                            if ($result.filter(':first').data('load-more') === 'False') {
                                page.find('#load-more').hide();
                            } else {
                                page.find('#load-more').show();
                            }
                            element.append($result.html());
                            element.data('page', ++currentPage);
                            page.find('#load-more a .fa-spinner').hide();
                            wo$('[data-enable-when-ready]').removeAttr('disabled');
                        }
                    });
                });

                controller.registerNotifications();
            },

            registerNotifications: function () {
                WhistleOut.notifications.add(function (notification) {
                    if (notification === 'AdsLoaded') {
                        var bottom = page.find('[data-adplacement="ArticleBottom"]');
                        var sidebar = page.find('[data-adplacement="ArticleSideBar"]');
                        if (bottom.is(':visible') && sidebar.is(':visible')) {
                            bottom.addClass('hidden-xs');
                        }
                    }
                });
            }
        };

        controller.init();
    };

    WhistleOut.initModule(import.meta.url, main);

}());